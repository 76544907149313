import { createApp } from 'vue'
import ElementPlus from 'element-plus'
import 'element-plus/dist/index.css'
import App from './App.vue'
import animate from "animate.css";
import * as ElementPlusIconsVue from '@element-plus/icons-vue'

// 定义特性标志
window.__VUE_PROD_DEVTOOLS__ = false;
window.__VUE_PROD_HYDRATION_MISMATCH_DETAILS__ = false;

const app = createApp(App)

app.use(ElementPlus)
app.mount('#app')
app.use(animate)
app.use(ElementPlusIconsVue)