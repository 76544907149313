<template>
  <div id="app">
    <div class="el-header-2" v-show="isVisable">
        <img src="./assets/lianxikefu.jpg" alt="" class="img-0">
    </div>
    <el-container>
      <div class="el-header-1" :class="control?'el-header-0':'el-header-1'"> 
        <div class="header-1">
          <div class="divImg-0">
            <img class="img-1" src="./assets/logo (2).png" alt="">
          </div>
          <div class="trigger-menu" >
            <ul class="">    
              <li><a href=""><span style="font-size: 20px;">官网首页</span></a></li>
              <li><a href=""><span style="font-size: 20px;">易要通</span></a></li>
              <li><a href=""><span style="font-size: 20px;">自要商城</span></a></li>
              <li><a href=""><span style="font-size: 20px;">益要商城</span></a></li>
              <li><a href=""><span style="font-size: 20px;">易要门店管理系统</span></a></li>
              <!-- <li><a href=""><span style="font-size: 20px;" @mouseenter="onEnterTd" @mouseleave="onLeaveTd">益要</span></a></li> -->
              <!-- <li>
                <a href="">
                  <div class="dropdown">
                    <span style="font-size: 20px;">益要</span>
                      <div class="dropdown-content">
                        <a href="#">益要商城</a>
                        <a href="#">易要门店管理系统</a>
                      </div>
                  </div>
                </a>
              </li> -->
            </ul>
          </div>
        </div>
        <!-- <div class="smallBox" :style="{display:visibility1}">
          <ul class="trigger-menu" style="list-style: none;">
            <li><span style="font-size: 20px;">易要商城</span></li>
            <li><span style="font-size: 20px;">易要门店管理系统</span></li>
          </ul>
        </div> -->
        
      </div>
      <el-main>
        <div class="backColor">
          <div class="brief-introduction">
                <img src="./assets/官网_01(新).jpg" alt="" srcset="" class="img-4">
          </div>
        </div>
        
        <!-- <div style="width: 100rem;display: block;margin: 0 auto">
          <el-carousel :interval="2000" type="card" height="30rem">
            <el-carousel-item v-for="item in imgList" :key="item.name">
            <img :src="item.src" alt="" height="100%" width="100%">
            </el-carousel-item>
          </el-carousel>
        </div> -->
        <div class="block">
          <!-- <div class="animate__animated animate__fadeInUp rowbox">
              <el-row :gutter="12">
                <el-col :span="8">
                  <el-card shadow="hover">
                  <div class="media">
                    <div class="iconbox">
                      <img src="./assets/yiyaotong.jpg" alt="">
                    </div>
                    <div class="media-body-1">
                    <h4>易要通</h4>
                    <p class="text-muted">
                      商城.....................
                    </p>
                    </div>
                  </div>
                  </el-card>
                </el-col>
                <el-col :span="8">
                  <el-card shadow="hover">
                  <div class="media">
                    <div class="iconbox">
                      <img src="./assets/logo(1).png" alt="">
                    </div>
                    <div class="media-body-2">
                      <h4>益要商城</h4>
                      <p class="text-muted">
                        商城..................
                      </p>
                      </div>
                  </div>
                  </el-card>
                </el-col>
                <el-col :span="8">
                  <el-card shadow="hover">
                  <div class="media">
                    <div class="iconbox">
                      <img src="./assets/ziyao.jpg" alt="">
                    </div>
                    <div class="media-body-3">
                    <h4>自要商城</h4>
                    <p class="text-muted">
                      商城......................
                    </p>
                    </div>
                  </div>
                  </el-card>
                </el-col>
              </el-row>
            </div> -->
            <div class="divBoXColor">
              <div class="divImg-1">
                <!-- <div class="text-center">
                  <h4 class="mainhead">易要通</h4>
                  <p class="text-muted" style="display: inline-block;">
                    易要通小程序商城隶属于深圳市康士力医药有限公司
                  </p>
                </div> -->
                <img class="animate__animated animate__zoomIn img-2" src="./assets/官网_02(新).jpg" alt="">
              </div>
            </div>
              <div class="divImg-2">
                <img class="animate__animated animate__zoomIn img-2" src="./assets/官网_03.jpg" alt="">
              </div>
            <div class="divBoXColor">
              <div class="divImg-3">
                <img class="animate__animated animate__zoomIn img-2" src="./assets/官网_04.jpg" alt="">
              </div>
            </div>
            <div class="divImg-4">
              <img class="animate__animated animate__zoomIn img-2" src="./assets/官网_05.jpg" alt="">
            </div>
        </div>

        <div class="divListImg">
          <div class="text-center">
            <h4 class="mainhead">证件齐全 安全无忧</h4>
            <p class="text-muted" style="display: inline-block;">
              
            </p>
          </div>
          <div class="box">   
            <a>
              <el-image style="width: 25rem; height: 20rem;margin: 0 20px;" ref="preview" :src="url" :preview-src-list="srcList"></el-image>
              <div class="text" style="width:25rem">
                  <h3>营业执照</h3>
              </div>
              <el-image style="width: 25rem; height: 20rem;margin: 0 20px;" ref="preview" :src="url1" :preview-src-list="srcList1"></el-image>
              <div class="text1" style="width:25rem">
                  <h3>药品网络交易服务第三方平台备案凭证</h3>
              </div>
              <el-image style="width: 25rem; height: 20rem;margin: 0 20px;" ref="preview" :src="url2" :preview-src-list="srcList2"></el-image>
              <div class="text2" style="width:25rem">
                  <h3>医疗器械网络交易服务第三方平台备案凭证</h3>
              </div>
              <el-image style="width: 25rem; height: 20rem;margin: 0 20px;" ref="preview" :src="url3" :preview-src-list="srcList3"></el-image>
              <div class="text3" style="width:25rem">
                  <h3>互联网药品信息服务资格证书</h3>
              </div>
            </a>  
        </div>     
        </div>
      </el-main>
      <div class="el-footer-1">
        <el-footer>
          <ul class="trigger-menu-1" >    
            <li>
              <a href="https://pic.imgdb.cn/item/667e3c0dd9c307b7e9074367.jpg" target="_blank">
                <span style="font-size: 15px;">
                  营业执照统一社会信用代码:
                </span>
                <p style="display:inline-block;font-size: 15px;">91440300741200704M</p>
              </a>
            </li>
            <li><a href="https://pic.imgdb.cn/item/667e3d01d9c307b7e9092457.jpg" target="_blank"><span style="font-size: 15px;  ">药品网络交易服务第三方平台备案凭证: </span><p style="display:inline-block;font-size: 15px;"> (粤)网药平台备字(2023)0008号</p></a></li><br>
            <li><a href="https://pic.imgdb.cn/item/667e3ce6d9c307b7e908f1c4.jpg" target="_blank"><span style="font-size: 15px;  ">医疗器械网络交易服务第三方平台备案凭证：</span><p style="display:inline-block;font-size: 15px;">(粤)网械平台备字(2022)第00034号</p></a></li>
            <li><a href="https://pic.imgdb.cn/item/667e3cbfd9c307b7e908acfb.jpg" target="_blank"><span style="font-size: 15px;">互联网药品信息服务资格证书编号: </span><p style="display:inline-block;font-size: 15px;">(粤)—经营性—2022—0006</p></a></li>
            <li><a href="https://pic.imgdb.cn/item/667e3c6ad9c307b7e90801fa.jpg" target="_blank"><p style="display:inline-block;font-size: 15px;">(粤)一非经营性一2024-0068</p></a></li><br> 
            <li><a href=""><span style="font-size: 15px;  ">©深圳市康士力医药有限公司版权所有 粤ICP备17110737号-2 </span></a></li>
            <li><a href=""><span style="font-size: 15px;  ">联系我们：0755-27572958 </span></a></li>
          </ul>
        </el-footer>
      </div>
    </el-container>
  </div>
</template>

<script>
export default {
  data(){
    return {
      activeIndex: '1',
      isVisable:false,
      control:false,
      visibility1:"none",
      url: 'https://pic.imgdb.cn/item/667e3c0dd9c307b7e9074367.jpg',
      url1: 'https://pic.imgdb.cn/item/667e3d01d9c307b7e9092457.jpg',
      url2: 'https://pic.imgdb.cn/item/667e3ce6d9c307b7e908f1c4.jpg',
      url3: 'https://pic.imgdb.cn/item/667e3c6ad9c307b7e90801fa.jpg',
      imgList: [
        {
          src:require("./assets/官网_02.jpg")
        },
        {
          src:require("./assets/官网_03.jpg")
        },
        {
          src:require("./assets/官网_04.jpg")
        }
      ],
      srcList: [
        'https://pic.imgdb.cn/item/667e3c0dd9c307b7e9074367.jpg',
        'https://pic.imgdb.cn/item/667e3d01d9c307b7e9092457.jpg',
        'https://pic.imgdb.cn/item/667e3ce6d9c307b7e908f1c4.jpg',
        'https://pic.imgdb.cn/item/667e3cbfd9c307b7e908acfb.jpg',
        'https://pic.imgdb.cn/item/667e3c6ad9c307b7e90801fa.jpg'
      ],
      srcList1: [
        'https://pic.imgdb.cn/item/667e3d01d9c307b7e9092457.jpg',
        'https://pic.imgdb.cn/item/667e3ce6d9c307b7e908f1c4.jpg',
        'https://pic.imgdb.cn/item/667e3cbfd9c307b7e908acfb.jpg',
        'https://pic.imgdb.cn/item/667e3c6ad9c307b7e90801fa.jpg',
        'https://pic.imgdb.cn/item/667e3c0dd9c307b7e9074367.jpg',

      ],
      srcList2: [
        'https://pic.imgdb.cn/item/667e3ce6d9c307b7e908f1c4.jpg',
        'https://pic.imgdb.cn/item/667e3cbfd9c307b7e908acfb.jpg',
        'https://pic.imgdb.cn/item/667e3c6ad9c307b7e90801fa.jpg',
        'https://pic.imgdb.cn/item/667e3c0dd9c307b7e9074367.jpg',
        'https://pic.imgdb.cn/item/667e3d01d9c307b7e9092457.jpg',


      ],
      srcList3: [
        'https://pic.imgdb.cn/item/667e3cbfd9c307b7e908acfb.jpg',
        'https://pic.imgdb.cn/item/667e3c6ad9c307b7e90801fa.jpg',
        'https://pic.imgdb.cn/item/667e3c0dd9c307b7e9074367.jpg',
        'https://pic.imgdb.cn/item/667e3d01d9c307b7e9092457.jpg',
        'https://pic.imgdb.cn/item/667e3ce6d9c307b7e908f1c4.jpg',

      ],
    }
  },
  //   methods:{
  //   //监听窗口滚动
  //   windowScrollListener() {
  //     //获取操作元素最顶端到页面顶端的垂直距离
  //     var scrollTop =document.body.scrollTop || document.documentElement.scrollTop;
  //     console.log(scrollTop)
  //     if (scrollTop >= 205.5) {
  //       this.isVisable = true; //大于480时显示元素
  //     }
  //     if (scrollTop < 205.5) {
  //       this.isVisable = false; //小于480时隐藏元素
  //     }
  //   },
  //   windowScrollListener1() {
  //     //获取操作元素最顶端到页面顶端的垂直距离
  //     var scrollTop = document.body.scrollTop || document.documentElement.scrollTop;
  //     if (scrollTop >= 1120) {
  //       this.DIS = true; //大于480时显示元素
  //       console.log("111")
  //     }
  //     if (scrollTop < 1120) {
  //       this.DIS = false; //小于480时隐藏元素
  //     }
  //   }
  // },
  // created() {
  //  //添加滚动监听事件
  //  //在窗口滚动时调用监听窗口滚动方法
  //  window.addEventListener('scroll', this.windowScrollListener);
  //  window.addEventListener('scroll', this.windowScrollListener1);
  // },
  methods:{
    handleSelect(key, keyPath) {
        console.log(key, keyPath);
      },
    windowScrollListener() {
      //获取操作元素最顶端到页面顶端的垂直距离
      var scrollTop =document.body.scrollTop || document.documentElement.scrollTop;
      if (scrollTop >= 405.5) {
        this.isVisable = true; //大于480时显示元素
      }
      if (scrollTop < 405.5) {
        this.isVisable = false; //小于480时隐藏元素
      }
    },
      //鼠标进入的事件。
      onEnterTd() {
          this.visibility1 = 'block'

      },
      //鼠标离开的事件。
      onLeaveTd() {
          this.visibility1 = 'none'
      },
  },
  
  created(){
    window.addEventListener('scroll',this.windowScrollListener)
    setInterval(()=>{
			var scrollTop =document.body.scrollTop || document.documentElement.scrollTop;
      if (scrollTop >= 45.5) {
        this.control = true; //大于480时显示元素
      }
      if (scrollTop < 45.5) {
        this.control = false; //小于480时隐藏元素
      }
		})
  }
}
</script>
<style scoped>

.dropbtn {
  color: white;
  padding: 16px;
  font-size: 16px;
  border: none;
}

.dropdown {
  position: relative;
  display: inline-block;
}

.dropdown-content {
  display: none;
  position: absolute;
  background-color: #f1f1f1;
  min-width: 160px;
  box-shadow: 0px 8px 16px 0px rgba(0,0,0,0.2);
  z-index: 1;
}

.dropdown-content a {
  color: black;
  padding: 12px 16px;
  text-decoration: none;
  display: block;
}

.dropdown-content a:hover {background-color: #ddd;}

.dropdown:hover .dropdown-content {display: block;}

.dropdown:hover .dropbtn {background-color: #ffffff;}
.body {
  width: 100%;
  height: 100%;
  
}


.img-0 {
  width: 100%;
}
.font-1 {
  font-size: 16px;
  text-align: center;
}
.backColor{
  width: 100%;
  background-color: #F4F9FF;
}
.img-4 {
  width: 65rem;
  height: 35rem;
}
.brief-introduction {
  width: 65rem;
  height: 35rem;
	display: block;
	margin:0 auto;
}

.text-center {
  text-align: center;
}
.el-footer-1 {
  height: 8rem;
  background-color: #000000;
  color: #777575;
  align-items: center;
  text-align: center;
}
.el-header-2 {
  height: 150px;
  width: 150px;
  position: fixed;
  z-index: 11;
  top: 50%;
  right: 0;
}
.el-header-1 {
  height: 5rem;
  position: fixed;
  width: 100%;
  z-index: 10;
  background: rgba(255, 255, 255, 0.95);
  box-shadow: 3px 3px rgb(255, 255, 255), -1em 0 0.4em rgb(0, 0, 0);
}
.el-header-0 {
  background: rgba(255, 255, 255, 0.95);
}
.text-muted {
  font-size: small;
  color: #6c757d
  
}
.iconbox img{
  width: 20%;
  height: 10%;
}
.media-body-1 {
  position: absolute;
  top: 1.5rem;
  left: 5rem;
}
.media-body-2 {
  position: absolute;
  top: 1.5rem;
  left: 25.8rem;
}
.media-body-3 {
  position: absolute;
  top: 1.5rem;
  left: 45.5rem;
}
.rowbox {
  width: 55rem;
  display: block;
  margin: 50px auto;
  animation-duration: 0.5s;
}
.divBoXColor {
  width: 100%;
  height: 35rem;
  background-color: #F7F7F7;
}
.divImg-1 {
  width: 65rem;
  height: 35rem;
  display: block;
	margin-right: auto;
  margin-left: auto;
  
}
.divImg-2 {
  width: 65rem;
  height: 35rem;
  display: block;
	margin-right: auto;
  margin-left: auto;
}
.divImg-3 {
  width: 65rem;
  height: 35rem;
  display: block;
	margin-right: auto;
  margin-left: auto;
}
.divImg-4 {
  width: 65rem;
  height: 50rem;
  display: block;
	margin-right: auto;
  margin-left: auto;
}
.img-2 {
  width: 100%;
  height: 100%;  
}
.el-main {
  padding: 80px 0 0 0;
  width: 100%;
  height: 100%;
  overflow-y: scroll;
}
.mainhead{
  color: rgba(37, 37, 37);
  font-weight: 600;
  font-size:1.5rem;
  line-height: 3rem;
  font-family: pingfang SC, helvetica neue, arial, hiragino sans gb, microsoft yahei ui, microsoft yahei, simsun;
}
.el-container {
  width: 100%;
  min-height: 100%;
  flex-direction: column;
  justify-content:space-between;
}
.el-main {
  height: 100%;
  overflow: hidden;
}
* {
  margin: 0;
  padding: 0;
  box-sizing: border-box;
  font-family:pingfang SC,helvetica neue,arial,hiragino sans gb,microsoft yahei ui,microsoft yahei,simsun;
}

.block {
  width: 100%;
  margin: auto;
  padding: auto;
}

.el-carousel__item:nth-child(2n) {
  background-color: #99a9bf;
}

.el-carousel__item:nth-child(2n+1) {
  background-color: #d3dce6;
}

.trigger-menu-1 {
  font-size: 0; /*消除li inline-block 为行内元素后的默认间距*/
  list-style: none;
  width: 100%;
  height: auto;
  margin-top: 8px
}
.trigger-menu-1 li {
  position: relative; /*关键点： 为每个li设置绝对定位*/
  display: inline-block; /*使li为块级元素，可以横向排列*/
  padding: 8px 8px;
}
.trigger-menu-1 li:after { /*hover状态下时的底部横线*/
  content: "";
  position: absolute; /*关键点： 该元素行对定位于 li*/
  left: 50%;
  width: 100%;
  opacity: 0;
  transform: translate(-50%) scaleX(0);
  transition: .2s ease-in-out;
}
.trigger-menu-1 li:hover::after {
  opacity: 1;
  transform: translate(-50%) scaleX(1);
}
.trigger-menu-1 a {

  font-size: 15px;
  font-weight: 700;
  color: #757575;
  line-height: 20px;
  text-decoration: none;
}
.trigger-menu-1 a:hover {
  color: #ffffff
}

.header-1 {
  width: 100%;
  height: 60px;
  display: flex;
  gap: 500px;
  align-items: center;
  justify-content: center;
}
.divImg-0{
  width: 12rem;
  height: 50px;
  margin-left: auto;
  margin-top: 22px;
}
.img-1 {
  width: 12rem;
  height: 100%;
}
.trigger-menu {
  font-size: 0; /*消除li inline-block 为行内元素后的默认间距*/
  list-style: none;
  margin-right: auto;
  margin-top: auto;
}
.trigger-menu li {
  position: relative; /*关键点： 为每个li设置绝对定位*/
  display: inline-block; /*使li为块级元素，可以横向排列*/
  padding: 0px 8px;
  border-top: 1px solid transparent
}
.trigger-menu li:after { /*hover状态下时的底部横线*/
  content: "";
  position: absolute; /*关键点： 该元素行对定位于 li*/
  left: 50%;
  width: 100%;
  opacity: 0;
  border-top: 1px solid #646464;
  transform: translate(-50%) scaleX(0);
  transition: .2s ease-in-out;
}
.trigger-menu li:hover::after {
  opacity: 1;
  transform: translate(-50%) scaleX(1);
}
.trigger-menu a {

  font-size: 15px;
  font-weight: 700;
  color: #575454;
  line-height: 35px;
  text-decoration: none;
}
.trigger-menu a:hover {
  color: #aca4a4
}

.smallBox {
  width: 10rem;
  height: 10rem;
}
.divListImg {
  width: 110rem;
  height: 20rem;
  display: block;
  margin-left: auto;
  margin-right: auto;
  margin-bottom: 80px;
}
.box{
 position: relative;
}

.box .text {
  height: 10rem;
  position: absolute;
  top: 0;
  bottom: 0;
  left: 20px;
  right: 0;
  text-align: center;
  -webkit-backface-visibility: hidden;
  backface-visibility: hidden;
  background: rgba(255, 255, 255, 0.6);   
  opacity: 0;
  -webkit-transition: all 0.35s ease-in-out;   
  -moz-transition: all 0.35s ease-in-out;
  transition: all 0.35s ease-in-out;
  display: inline-block
}
.box .text1 {
  height: 10rem;
  position: absolute;
  top: 0;
  bottom: 0;
  left: 460px;
  right: 0;
  text-align: center;
  -webkit-backface-visibility: hidden;
  backface-visibility: hidden;
  background: rgba(255, 255, 255, 0.6);   
  opacity: 0;
  -webkit-transition: all 0.35s ease-in-out;   
  -moz-transition: all 0.35s ease-in-out;
  transition: all 0.35s ease-in-out;
  display: inline-block
}
.box .text2 {
  height: 10rem;
  position: absolute;
  top: 0;
  bottom: 0;
  left: 900px;
  right: 0;
  text-align: center;
  -webkit-backface-visibility: hidden;
  backface-visibility: hidden;
  background: rgba(255, 255, 255, 0.6);   
  opacity: 0;
  -webkit-transition: all 0.35s ease-in-out;   
  -moz-transition: all 0.35s ease-in-out;
  transition: all 0.35s ease-in-out;
  display: inline-block
}
.box .text3 {
  height: 10rem;
  position: absolute;
  top: 0;
  bottom: 0;
  left: 1340px;
  right: 0;
  text-align: center;
  -webkit-backface-visibility: hidden;
  backface-visibility: hidden;
  background: rgba(255, 255, 255, 0.6);   
  opacity: 0;
  -webkit-transition: all 0.35s ease-in-out;   
  -moz-transition: all 0.35s ease-in-out;
  transition: all 0.35s ease-in-out;
  display: inline-block
}


.box a:hover .text {
       opacity: 1;   
}
.box a:hover .text1 {
       opacity: 1;   
}
.box a:hover .text2 {
       opacity: 1;   
}
.box a:hover .text3 {
       opacity: 1;   
}

.span-1 {
  position:relative;
  width:10rem;
  top:-3rem;
  left: 3%;
  font-size:20px
}

#app {
  position: absolute;
  width: 100%;
  top: 0;
  right: 0;
  left: 0;
  bottom: 0;
}
</style>


